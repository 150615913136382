import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import artistImage from './../../images/avatar_connection_refused_800_3.jpg';
import spotifyIcon from './../../images/platforms/spotify.png';
import appleMusicIcon from './../../images/platforms/apple-music.png';
import youtubeMusicIcon from './../../images/platforms/youtube-music.png';
import amazonMusicIcon from './../../images/platforms/amazon-music.png';
import deezerIcon from './../../images/platforms/deezer.png';
import yandexIcon from './../../images/platforms/yandex.png';
import soundcloudIcon from './../../images/platforms/soundcloud.png';

const ArtistPage = () => {
  const platformLinks = {
    spotify: 'https://open.spotify.com/artist/3lThr54UGAxjbAcuTkW1ym',
    'apple-music': 'https://music.apple.com/us/artist/coderr/1540430329',
    'youtube-music': 'https://music.youtube.com/channel/UCIP1sQ_aGxcQibMOV5-0mnA',
    'amazon-music': 'https://music.amazon.com/artists/B08NPFYF4N/coderr',
    deezer: 'https://www.deezer.com/en/artist/113468032',
    yandex: 'https://music.yandex.ru/artist/10348877',
    soundcloud: 'https://soundcloud.com/edeyrmh8alxv'
  };

  const platformIcons = {
    spotify: spotifyIcon,
    'apple-music': appleMusicIcon,
    'youtube-music': youtubeMusicIcon,
    'amazon-music': amazonMusicIcon,
    deezer: deezerIcon,
    yandex: yandexIcon,
    soundcloud: soundcloudIcon
  };

  useEffect(() => {
    ReactGA.initialize('G-W8BX8SNSQR');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('1036348951543648');
        ReactPixel.pageView();
      });
  }, []);

  const handlePlatformClick = (platform) => {
    // Send Google Analytics event
    ReactGA.event({
      category: 'Music Platform',
      action: 'Click',
      label: platform
    });

    if (platform == 'spotify') {
        ReactGA.event({
          category: 'Music Platform',
          action: 'Click_Spotify',
          label: platform
        });
    } else {
        ReactGA.event({
          category: 'Music Platform',
          action: 'Click_Other',
          label: platform
        });
    }

    // Send Facebook Pixel event
    if (window.fbq) {
        if (platform == 'spotify') {
            window.fbq('track', 'ViewContent', {
                content_name: `${platform} Click`,
                content_category: 'Music Platform',
                content_ids: [`${platform}_fake_memories`],
                content_type: 'product',
            });
        } else {
            window.fbq('track', 'AddToWishlist', {
                content_name: `${platform} Click`,
                content_category: 'Music Platform',
                content_ids: [`${platform}_fake_memories`],
                content_type: 'product',
            });
        }
    }
  };

  return (
    <div className="landing-page">
      <canvas id="background-canvas" className="background-canvas"></canvas>
      <div className="background-image overlay-artist" style={{backgroundImage: `url(${artistImage})`}}></div>
      <div className="card">
        <div className="image-container">
          <img
            src={artistImage}
            alt="Coderr"
            className="cover-image"
          />
        </div>

        <div className="content">
          <div className="artist-info">
            <h2 className="artist">Coderr</h2>
            <p className="artist-description">Electronic music producer</p>
          </div>

          <div className="buttons">
            {Object.entries(platformLinks).map(([platform, link]) => (
              <a
                key={platform}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={`btn ${platform}`}
                onClick={() => handlePlatformClick(platform)}
              >
                <img src={platformIcons[platform]} alt={platform} className="platform-icon" />
                <span className="play-icon">▶</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistPage;