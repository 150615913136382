import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThisIsTheLife from './components/pages/ThisIsTheLife';
import ArtistPage from './components/pages/ArtistPage';
import NewsletterSignup from './components/pages/NewsletterSignup';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ArtistPage />} />
        <Route path="/artist" element={<ArtistPage />} />
        <Route path="/this-is-the-life" element={<ThisIsTheLife />} />
        <Route path="/subscribe" element={<NewsletterSignup />} />
      </Routes>
    </Router>
  );
};

export default App;