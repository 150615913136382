import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import artistImage from './../../images/this_is_the_life_800_3.jpg';
import newsletterImage from './../../images/this_is_the_life_800_3.jpg';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    ReactGA.initialize('G-W8BX8SNSQR');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('1176522883399295');
        ReactPixel.pageView();
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('https://music.coderr.online/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Subscription failed');
      }

    // Track the submission event
    ReactGA.event({
      category: 'Newsletter',
      action: 'Submit',
      label: 'Email Subscription'
    });

    if (window.fbq) {
      window.fbq('track', 'Lead', {
        content_name: 'Newsletter Signup',
        content_category: 'Subscription',
      });
    }

      setIsSubmitted(true);
    } catch (error) {
      setError(error.message);
      console.error('Subscription error:', error);
    }
  };

  return (
    <div className="landing-page">
      <canvas id="background-canvas" className="background-canvas"></canvas>
      <div className="background-image overlay-this-is-the-life" style={{backgroundImage: `url(${artistImage})`}}></div>
      <div className="card newsletter-card">
        <div className="newsletter-image">
          <img src={newsletterImage} alt="Coderr Newsletter" />
        </div>
        <div className="content">
          <h2 className="artist">Coderr's Newsletter</h2>
          <p className="artist-description">Stay updated with new releases and exclusive content</p>

          {!isSubmitted ? (
            <form onSubmit={handleSubmit} className="newsletter-form">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="email-input"
              />
              <button type="submit" className="submit-btn">Subscribe</button>
              {error && <p className="error-message">{error}</p>}
            </form>
          ) : (
            <p className="success-message">Thank you for subscribing!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterSignup;